import React from 'react';
//import {ComplianceApp} from './components/permit/ComplianceApp';
import './App.css';
import {ToastProvider} from 'react-toast-notifications';
import { UnitMediaApp } from './components/UnitMediaApp';

function App() {
    return (
        <ToastProvider autoDismiss autoDismissTimeout={5000} placement="top-right">
            <div className="admin-app">
                <div className="container-fluid">
                    <div className="row">
                        <div className="container-custom">
                            <UnitMediaApp />
                        </div>
                    </div>
                </div>
            </div>
        </ToastProvider>
    );
}

export default App;
