import React from "react";
import ReactDOM from "react-dom";
import {ConnectToServices, authManager} from "@common/authentication";
import App from "./App";
import _ from "lodash";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles.base.scss";

const params = new URLSearchParams(window.location.search);

let token: string = params.get("token");
if (_.isEmpty(token)) {
    token = authManager.getTokenFromAdmin();
}

ReactDOM.render(
    <React.StrictMode>
        <ConnectToServices tokenFromAdmin={token}>
            <App />
        </ConnectToServices>
    </React.StrictMode>,
    document.getElementById("root")
);
