import React, {useState, useRef, useEffect} from "react";
import {ThemeProvider, Modal, Button} from "@vacasa/react-components-lib";
import _default from "@vacasa/react-components-lib/lib/themes/default";
import {UnitMedia} from "@common/typing";
import {Configuration} from "@common/configuration";
import styles from "../UnitMediaApp.module.scss";
import * as _ from "lodash";

export interface ModalFormProps {
    unitMediaOpen: string;
    setUnitMediaOpen: Function;
    unitMedia: UnitMedia;
    updateUnitMedia: Function;
    setUnitMedia: Function;
    inputUnitMediaCode: string;
    setInputUnitMediaCode: Function;
}

export const ModalForm: React.FunctionComponent<ModalFormProps> = (props) => {
    const {unitMedia, updateUnitMedia, setUnitMedia, unitMediaOpen, inputUnitMediaCode, setInputUnitMediaCode, setUnitMediaOpen} = props;
    const [showIframe, setShowIframe] = useState(false);
    const inputCodeRef = useRef(null);

    let title, description, code, path, key;
    if (unitMediaOpen === Configuration.PROVIDER_NAME.INSIDEMAPS) {
        key = "insidemaps";
        title = Configuration.PROVIDER_NAME.INSIDEMAPS;
        description = "Enter InsideMaps URL below and click load to view the InsideMaps tour for this unit.";
        path = unitMedia?.insidemaps?.path;
    } else {
        key = "matterport";
        title = Configuration.PROVIDER_NAME.MATTERPORT;
        description = "Enter Matterport ID below and click load to view the Matterport tour for this unit.";
        path = unitMedia?.matterport?.path;
    }

    useEffect(() => {
        const unitMediaCode = gettingCode();
        setInputUnitMediaCode(unitMediaCode as any);
    }, []);

    const gettingCode = (): string => {
        return unitMediaOpen === Configuration.PROVIDER_NAME.INSIDEMAPS ? unitMedia?.insidemaps?.code : unitMedia?.matterport?.code;
    };

    const handleLoadButton = () => {
        setShowIframe(true);
    };

    const handleChangeUnitMediaCode = (e) => {
        setShowIframe(false);
        setInputUnitMediaCode(e.target.value);
    };

    const handleCancelButton = () => {
        closeModal();
    };

    const handleConfirmButton = () => {
        const unitMediaClone = _.cloneDeep(unitMedia);
        unitMediaClone[key].code = inputUnitMediaCode;
        updateUnitMedia(unitMediaClone);
        closeModal();
    };

    const closeModal = () => {
        setShowIframe(false);
        setUnitMediaOpen(null);
    };

    const isValidPathCode = (path, code) => {
        if (_.isEmpty(code)) return false;
        if (_.isEmpty(path)) {
            //only for InsideMaps
            if (!code.includes("insidemaps.com")) return false;
        }

        return true;
    };

    return (
        <ThemeProvider theme={_default}>
            <Modal
                showModal={true}
                setShowModal={() => {
                    closeModal();
                }}
                size="medium"
            >
                <div>
                    <h2>{title}</h2>
                    <div className="row">
                        <div className="col-md-8">
                            <label htmlFor="unitMediaCode" className="form-label">
                                {description}
                            </label>
                            <input
                                ref={inputCodeRef}
                                type="text"
                                className="form-control"
                                id="unitMediaCode"
                                placeholder="Example: wA6xWTLwjwc"
                                onChange={(e) => handleChangeUnitMediaCode(e)}
                                value={inputUnitMediaCode || ""}
                            />
                        </div>
                        <div className="col-md-4">
                            <Button type="button" variant="info" customClass={styles.btnLoad} onClick={handleLoadButton}>
                                Load
                            </Button>
                        </div>
                    </div>
                    {showIframe ? (
                        <React.Fragment>
                            <div className="row">
                                <div className="col">
                                    {isValidPathCode(path, inputUnitMediaCode) ? (
                                        <iframe src={`${path || ""}${inputUnitMediaCode || ""}`} width="550" height="320"></iframe>
                                    ) : (
                                        <span>Path not found</span>
                                    )}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <div style={{marginTop: "25px", textAlign: "right"}}>
                                        <hr />
                                        <Button variant="secondary" onClick={handleCancelButton}>
                                            Cancel
                                        </Button>
                                        &nbsp;
                                        <Button variant="primary" onClick={handleConfirmButton}>
                                            Confirm
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    ) : (
                        <span></span>
                    )}
                </div>
            </Modal>
        </ThemeProvider>
    );
};
