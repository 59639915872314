import React, {useState, useEffect} from "react";
import {UnitMediaService} from "@common/units-api";
import {authManager} from "@common/authentication";
import {UnitMedia} from "@common/typing";
import {UnitMediaAppRender} from "./UnitMediaAppRender";
import {ParseHelper} from "../helpers/parse-helper";
import _ from "lodash";

let unitId: number = null;
let uuid: string = null;
let userEmail: string = null;

export const UnitMediaApp: React.FunctionComponent<any> = (props) => {
    const [loadingApp, setLoadingApp] = useState(false);
    const [loading, setLoading] = useState(false);
    const [unitMedia, setUnitMedia] = useState(null);

    const token = authManager.getJwt();
    const unitMediaService = new UnitMediaService(token);

    /** Load and set data */
    useEffect(() => {
        (async () => {
            try {
                setLoadingApp(true);
                await loadData();
            } catch (e) {
                console.error("error unit media app", e);
            } finally {
                setLoadingApp(false);
            }
        })();
    }, []);

    /** Resize iframe for each render */
    useEffect(() => {
        resizeContent();
    });

    const loadData = async () => {
        if (_.isEmpty(unitId)) {
            const info = authManager.getInfoFromAdmin<{user: string; unitId: number}>();
            unitId = info.unitId;
            userEmail = info.user;
        }

        if (_.isEmpty(uuid)) uuid = await unitMediaService.getUuidByLegacyUnitId(unitId);

        const unitMedia = await fetchUnitMedia(uuid);
        setUnitMedia(unitMedia);
    };

    const updateUnitMedia = async (unitMedia: UnitMedia) => {
        setLoading(true);
        if (!_.isEmpty(unitMedia.insidemaps.code)) {
            if (unitMedia.insidemaps.id) {
                await unitMediaService.updateUnitMedia(unitMedia.insidemaps, uuid, userEmail);
            } else {
                await unitMediaService.createUnitMedia(unitMedia.insidemaps, uuid, userEmail);
            }
        }
        if (!_.isEmpty(unitMedia.matterport.code)) {
            if (unitMedia.matterport.id) {
                await unitMediaService.updateUnitMedia(unitMedia.matterport, uuid, userEmail);
            } else {
                await unitMediaService.createUnitMedia(unitMedia.matterport, uuid, userEmail);
            }
        }
        const unitMediaUpdated = await fetchUnitMedia(uuid);
        setUnitMedia(unitMediaUpdated);
        setLoading(false);
    };

    const fetchUnitMedia = async (uuid: string): Promise<UnitMedia> => {
        const unitMediaData = await unitMediaService.getUnitMedia(uuid);
        return ParseHelper.fromUnitMediaDataToUnitMedia(unitMediaData);
    };

    const resizeContent = () => {
        const height = 800; //set 800px of height
        window.parent.postMessage({action: `${height}px`, type: "resize"}, "*");
    };

    return (
        <UnitMediaAppRender
            unitMedia={unitMedia}
            updateUnitMedia={updateUnitMedia}
            setUnitMedia={setUnitMedia}
            loadingApp={loadingApp}
            loading={loading}
        />
    );
};
