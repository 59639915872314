import React from "react";

export interface FooterInfoProps {
    createdAt?: string;
    createdBy?: string;
    modifiedAt?: string;
    modifiedBy?: string;
}

export const FooterInfo: React.FunctionComponent<FooterInfoProps> = (props) => {
    return (
        <React.Fragment>
            <div className="col-md-3">
                <p>
                    {props.createdAt ? (
                        <span>
                            <b>Created at</b> {props.createdAt}
                        </span>
                    ) : (
                        <span></span>
                    )}
                </p>
            </div>
            <div className="col-md-3">
                <p>
                    {props.createdBy ? (
                        <span>
                            <b>Created by</b> {props.createdBy}
                        </span>
                    ) : (
                        <span></span>
                    )}
                </p>
            </div>
            <div className="col-md-3">
                <p>
                    {props.modifiedAt ? (
                        <span>
                            <b>Modified at</b> {props.modifiedAt}
                        </span>
                    ) : (
                        <span></span>
                    )}
                </p>
            </div>
            <div className="col-md-3">
                <p>
                    {props.modifiedBy ? (
                        <span>
                            <b>Modified by</b> {props.modifiedBy}
                        </span>
                    ) : (
                        <span></span>
                    )}
                </p>
            </div>
        </React.Fragment>
    );
};
