import React, {useState} from "react";
import styles from "./UnitMediaApp.module.scss";
import {FooterInfo} from "./FooterInfo/FooterInfo";
import {UnitMedia} from "../../../../@common/typing";
import {ModalForm} from "./ModalForm/ModalForm";
import {Configuration} from "@common/configuration";
import _default from "@vacasa/react-components-lib/lib/themes/default";
import {ThemeProvider, Button, Input, Image} from "@vacasa/react-components-lib";
import * as _ from "lodash";

interface UnitMediaAppRenderProps {
    unitMedia: UnitMedia;
    updateUnitMedia: Function;
    setUnitMedia: Function;
    loadingApp: boolean;
    loading: boolean;
}

export const UnitMediaAppRender: React.FunctionComponent<UnitMediaAppRenderProps> = (props) => {
    const {loadingApp, unitMedia, updateUnitMedia, setUnitMedia, loading} = props;

    const [unitMediaOpen, setUnitMediaOpen] = useState(null);
    const [inputUnitMediaCode, setInputUnitMediaCode] = useState(null);
    const [providerClicked, setProviderClicked] = useState(null);

    const handleEditButttonMatterport = () => {
        setUnitMediaOpen(Configuration.PROVIDER_NAME.MATTERPORT);
    };

    const handleEditButttonInsidemaps = () => {
        setUnitMediaOpen(Configuration.PROVIDER_NAME.INSIDEMAPS);
    };

    const handleActiveMatterport = (e) => {
        setProviderClicked("matterport");
        const active = e.target.checked;
        const unitMediaClone = _.cloneDeep(unitMedia);
        unitMediaClone.matterport.active = active;

        if (active && unitMedia?.insidemaps?.active) {
            if (window.confirm(`if you activate this media, InsideMaps will be deactivated. Do you wish to continue?`)) {
                unitMediaClone.insidemaps.active = false;
                updateUnitMedia(unitMediaClone);
            }
        } else {
            updateUnitMedia(unitMediaClone);
        }
    };

    const handleActiveInsidemaps = (e) => {
        setProviderClicked("insidemaps");
        const active = e.target.checked;
        const unitMediaClone = _.cloneDeep(unitMedia);
        unitMediaClone.insidemaps.active = active;

        if (active && unitMedia?.matterport?.active) {
            if (window.confirm(`if you activate this media, Matterport will be deactivated. Do you wish to continue?`)) {
                unitMediaClone.matterport.active = false;
                updateUnitMedia(unitMediaClone);
            }
        } else {
            updateUnitMedia(unitMediaClone);
        }
    };

    return (
        <div>
            <div className="container-fluid">
                {loadingApp ? (
                    <div className="row">
                        <div className={`col ${styles.centerContent}`}>
                            <h6>Loading data...</h6>
                        </div>
                    </div>
                ) : (
                    <ThemeProvider theme={_default}>
                        <div className="row">
                            <div className={`col ${styles.contentUnitMedia}`}>
                                <form>
                                    <hr />
                                    <div className={`row ${unitMedia?.matterport?.active ? styles.active : ""}`}>
                                        <div className="col">
                                            <div className="row">
                                                <div className="col">
                                                    <h5>Matterport</h5>
                                                </div>
                                                <div className="col">
                                                    <div className="form-check form-switch">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            id="matterportActive"
                                                            onChange={handleActiveMatterport}
                                                            checked={unitMedia?.matterport?.active ? true : false}
                                                            disabled={_.isEmpty(unitMedia?.matterport?.code) || loading === true ? true : false}
                                                        />
                                                        <label className="form-check-label" htmlFor="matterportActive">
                                                            Active{" "}
                                                            {loading && providerClicked === "matterport" ? (
                                                                <Image.Spinner width="20" />
                                                            ) : (
                                                                <React.Fragment />
                                                            )}
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <Input
                                                        label="Unique Tour ID"
                                                        type="text"
                                                        placeholder=""
                                                        disabled={true}
                                                        value={unitMedia?.matterport?.code}
                                                        onChange={() => {}}
                                                    />
                                                </div>
                                                <div className="col">
                                                    <Button
                                                        type="button"
                                                        variant="primary"
                                                        customClass={`${styles.customEditBtn} left`}
                                                        disabled={false}
                                                        onClick={handleEditButttonMatterport}
                                                    >
                                                        Edit
                                                    </Button>
                                                </div>
                                            </div>
                                            <br />
                                            <div className="row">
                                                <FooterInfo
                                                    createdAt={unitMedia?.matterport?.created_at}
                                                    createdBy={unitMedia?.matterport?.created_by}
                                                    modifiedAt={unitMedia?.matterport?.modified_at}
                                                    modifiedBy={unitMedia?.matterport?.modified_by}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className={`row ${unitMedia?.insidemaps?.active ? styles.active : ""}`}>
                                        <div className="col">
                                            <div className="row">
                                                <div className="col">
                                                    <h5>InsideMaps</h5>
                                                </div>
                                                <div className="col">
                                                    <div className="form-check form-switch">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            id="insideMapsActive"
                                                            onChange={handleActiveInsidemaps}
                                                            checked={unitMedia?.insidemaps?.active ? true : false}
                                                            disabled={_.isEmpty(unitMedia?.insidemaps?.code) || loading === true ? true : false}
                                                        />
                                                        <label className="form-check-label" htmlFor="insideMapsActive">
                                                            Active
                                                            {loading && providerClicked === "insidemaps" ? (
                                                                <Image.Spinner width="20" />
                                                            ) : (
                                                                <React.Fragment />
                                                            )}
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <Input
                                                        type="text"
                                                        label="InsideMaps URL"
                                                        disabled={true}
                                                        value={unitMedia?.insidemaps?.code}
                                                        onChange={() => {}}
                                                    />
                                                </div>
                                                <div className="col">
                                                    <Button
                                                        type="button"
                                                        variant="primary"
                                                        customClass={`${styles.customEditBtn} left`}
                                                        disabled={false}
                                                        onClick={handleEditButttonInsidemaps}
                                                    >
                                                        Edit
                                                    </Button>
                                                </div>
                                            </div>
                                            <br />
                                            <div className="row">
                                                <FooterInfo
                                                    createdAt={unitMedia?.insidemaps?.created_at}
                                                    createdBy={unitMedia?.insidemaps?.created_by}
                                                    modifiedAt={unitMedia?.insidemaps?.modified_at}
                                                    modifiedBy={unitMedia?.insidemaps?.modified_by}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            {unitMediaOpen ? (
                                <ModalForm
                                    unitMedia={unitMedia}
                                    unitMediaOpen={unitMediaOpen}
                                    setUnitMediaOpen={setUnitMediaOpen}
                                    updateUnitMedia={updateUnitMedia}
                                    setUnitMedia={setUnitMedia}
                                    inputUnitMediaCode={inputUnitMediaCode}
                                    setInputUnitMediaCode={setInputUnitMediaCode}
                                />
                            ) : (
                                <span></span>
                            )}
                        </div>
                    </ThemeProvider>
                )}
            </div>
        </div>
    );
};
