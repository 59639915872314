import {ComplianceItem, InsideMaps, Matterport, Permit, PropertyTax, UnitMedia, UnitMediaData} from "@common/typing";
import {Configuration} from "@common/configuration";
import moment from "moment";
import _ from "lodash";

export class ParseHelper {
    static fromPermitToComplianceItem(permit: Permit): ComplianceItem {
        return {...permit, attributes: {...permit.attributes, type: permit.attributes.permit_type, number: permit.attributes.permit_number}};
    }

    static fromPropertyTaxToComplianceItem(propertyTax: PropertyTax): ComplianceItem {
        return {
            ...propertyTax,
            attributes: {...propertyTax.attributes, type: propertyTax.attributes.tax_type, number: propertyTax.attributes.tax_number},
        };
    }

    static fromComplianceItemToPermit(item: ComplianceItem): Permit {
        return {...item, attributes: {...item.attributes, permit_type: item.attributes.type, permit_number: item.attributes.number}};
    }

    static fromComplianceItemToPropertyTax(item: ComplianceItem): PropertyTax {
        return {
            ...item,
            attributes: {...item.attributes, tax_type: item.attributes.type, tax_number: item.attributes.number},
        };
    }

    static fromUnitMediaDataToUnitMedia(unitMedia: Array<UnitMediaData>): UnitMedia {
        let matterport = new Matterport();
        let insidemaps = new InsideMaps();

        for (const um of unitMedia) {
            if (um.attributes.media_provider.name === Configuration.PROVIDER_NAME.INSIDEMAPS) {
                ParseHelper.parseUnitMedia(insidemaps, um);
            } else if (um.attributes.media_provider.name === Configuration.PROVIDER_NAME.MATTERPORT) {
                ParseHelper.parseUnitMedia(matterport, um);
            }
        }

        //TODO: we need a new endpoint in units service for retrieve the providers
        if (_.isEmpty(matterport.path)) matterport.path = Configuration.MATTERPORT_PATH;

        return {
            matterport,
            insidemaps,
        };
    }

    private static parseUnitMedia(unitMedia: Matterport | InsideMaps, unitMediaData: UnitMediaData): void {
        unitMedia.id = unitMediaData.attributes.id;
        unitMedia.code = unitMediaData.attributes.code;
        unitMedia.active = unitMediaData.attributes.active;
        unitMedia.path = unitMediaData.attributes.media_provider.path;
        unitMedia.created_at = moment(unitMediaData.attributes.created_at).format("yyyy-MM-DD");
        unitMedia.created_by = unitMediaData.attributes.created_by;
        unitMedia.modified_at = moment(unitMediaData.attributes.modified_at).format("yyyy-MM-DD");
        unitMedia.modified_by = unitMediaData.attributes.modified_by;
    }
}
